<template>
  <div class="admin container-fluid">
    <div class="row">
      <div
        v-bind:class="{
          'transition fadeIn col-7': true,
          'col-lg-7': !range,
          'col-lg-7': range,
        }"
      >
        <signal
          :ref="'signal'"
          :index="0"
          v-bind:key="i"
          v-for="(signal, i) in [currentSignals[0]]"
          header="left"
        />
        <slider
          class="w-100"
          :rating="false"
          :crop="false"
          :isNextTime="true"
        />
      </div>

      <div
        class="mt-2"
        v-bind:class="{
          'transition fadeIn col-5': true,
          'col-lg-5': !range,
          'col-lg-5': range,
        }"
      >
        <span
          v-if="prettifyHour(this.$store.state.endDate) !== 'Fecha inválida'"
        >
          <p style="color: white">
            Catalogando el bloque de las
            {{ prettifyHour(this.$store.state.endDate) }}
          </p>
        </span>
        <b-form @submit="onSubmit">
          <b-row>
            <b-col
              :cols="crud.col"
              :id="'input-group-crud' + c"
              :label="crud.name"
              :label-for="'input-crud' + c"
              v-for="(crud, c) in cruds"
              v-bind:key="c"
            >
              <b-form-group
                class="labelStyle"
                :id="'input-group-crud' + c"
                :label="crud.name"
                :label-for="'input-crud' + c"
              >
                <v-select
                  :name="'input-crud' + c"
                  :id="'input-crud' + c"
                  :filterable="false"
                  :taggable="true"
                  :multiple="crud.multiple"
                  v-model="form[crud.slug.toLowerCase()]"
                  :label="crud.label"
                  :options="crud.data"
                  @search="crud.onSearch"
                >
                  <template slot="no-options">
                    Escribe para buscar {{ crud.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group class="labelStyle" label="Miscelaneo">
                <b-form-input
                  v-model="form['miscelaneo']"
                  placeholder="Ingresa un link"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group class="labelStyle" label="Descripción">
                <b-form-textarea
                  id="textarea"
                  v-model="form['description']"
                  placeholder="Ingresa una descripción"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" class="mt-2">
              <b-button type="submit" variant="primary" class="mr-4"
                >Registrar</b-button
              >
              <b-button @click="reset()" type="reset" variant="danger"
                >Limpiar Formulario</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import moment from "moment";
import Slider from "@/components/Slider.vue";
import Loader from "@/components/Loader.vue";
import signal from "@/components/Signal.vue";
import { Multipane, MultipaneResizer } from "vue-multipane";
import {
  searchCountry,
  searchPerson,
  searchShow,
  searchTopic,
  searchAge,
  searchCategory,
  getCatalog,
  createCatalog,
  updateCatalog,
  searchCatalogByDate,
  createPerson,
  createTopic,
  createCategory,
} from "@/helpers/API.js";

export default {
  name: "Vistaunica",
  components: {
    Loader,
    signal,
    Multipane,
    MultipaneResizer,
    Slider,
  },
  data() {
    var that = this;
    var today = new Date(Date.now());
    setInterval(() => {
      that.time = new Date(Date.now()).valueOf();
      that.maxTime = that.time;
    }, 1000);
    return {
      range: 0,
      loaded: false,
      countries: [],
      minTime: new Date(today).setHours(today.getHours() - 2).valueOf(),
      maxTime: today.valueOf(),
      currentTime: today.valueOf(),
      isHandlerDragging: false,
      form: {},
      options: [],
      API: {
        searchCountry,
        searchPerson,
        searchShow,
        searchTopic,
        searchAge,
        searchCategory,
        createPerson,
        createTopic,
        createCategory,
      },
      cruds: [
        // primera fila
        {
          name: "Pais origen",
          slug: "Country",
          label: "country_name",
          data: [],
          col: 3,
          onSearch(search, loading) {
            that.onSearch(search, loading, "Country");
          },
        },
        {
          name: "Nombre programa",
          slug: "Show",
          label: "name",
          data: [],
          col: 5,
          onSearch(search, loading) {
            that.onSearch(search, loading, "Show");
          },
        },
        {
          name: "Tipo",
          slug: "Tipo",
          label: "name",
          col: 4,
          data: ["Serie", "Capitulo", "Publicidad"],
        },
        // Fin primera fila

        // Segunda fila
        {
          name: "Emisión",
          slug: "emision",
          label: "name",
          col: 3,
          data: ["En vivo", "Grabado"],
        },
        {
          name: "Exhibición",
          slug: "exhibicion",
          label: "name",
          col: 5,
          data: [
            "Primera exhibición",
            "Segunda exhibición",
            "Tercera exhibición",
            "Cuarta exhibición",
          ],
        },
        {
          name: "Programa especial",
          slug: "PG",
          label: "name",
          col: 4,
          data: ["SI", "No"],
        },

        // Fin segunda fila
        {
          name: "Rostros",
          slug: "Person",
          label: "name",
          multiple: true,
          data: [],
          col: 12,
          onSearch(search, loading) {
            that.onSearch(search, loading, "Person");
          },
        },
        {
          name: "Temas",
          slug: "Topic",
          label: "name",
          multiple: true,
          data: [],
          col: 12,
          onSearch(search, loading) {
            that.onSearch(search, loading, "Topic");
          },
        },
        {
          name: "Clasificación de Edad",
          slug: "Age",
          label: "name",
          data: [],
          col: 12,
          onSearch(search, loading) {
            that.onSearch(search, loading, "Age");
          },
        },
        {
          name: "Categorías",
          slug: "Category",
          label: "name",
          multiple: true,
          data: [],
          col: 12,
          onSearch: (search, loading) => {
            that.onSearch(search, loading, "Category");
          },
        },
      ],
    };
  },
  async created() {
    var that = this;
    if (!that.$session.exists()) {
      window.location = "/login";
    }

    if (
      that.$route.params.hasOwnProperty("id") &&
      that.$route.params.id != undefined
    ) {
      let result = await getCatalog(that.$route.params.id);
      that.form = result.data;
    }
  },
  async mounted() {
    var that = this;
    setTimeout(() => {
      that.currentSignals = [];
      that.currentSignals.push(that.signals[0]);
      // the next line is for show data inside select
      that.onSearch("Chile", false, "Country");
      that.onSearch(" ", false, "Person");
      that.onSearch(" ", false, "Show");
      that.onSearch(" ", false, "Topic");
      that.onSearch(" ", false, "Age");
      that.onSearch(" ", false, "Category");

      //initialization variable
      that.form["tipo"] = "Capitulo";
      that.form["exhibicion"] = "Primera exhibición";
      that.form["emision"] = "En vivo";
      that.form["pg"] = "No";
    }, 500);

    setTimeout(() => {
      that.checkAvailable();
    }, 750);

    that.form.user = that.user;

    that.$root.$on("checkAvailable", () => {
      that.checkAvailable();
    });
  },
  methods: {
    calcular() {
      let times = [
        moment(this.$store.state.endDate).format("YYYY-MM-DD HH:00:00"),
        moment(this.$store.state.endDate).format("YYYY-MM-DD HH:15:00"),
        moment(this.$store.state.endDate).format("YYYY-MM-DD HH:30:00"),
        moment(this.$store.state.endDate).format("YYYY-MM-DD HH:45:00"),
      ];
      let actualTime = moment(this.$store.state.endDate);

      let diffTimeInMinutes = [];
      //sacamos la diferencia de tiempo y los numeros lo convertimos en positivos
      times.forEach((time) => {
        diffTimeInMinutes.push(Math.abs(actualTime.diff(time, "minutes")));
      });
      //obtenemos el valor minimo en minutos
      let minValue = Math.min(...diffTimeInMinutes);
      //obtenemos la posición de ese valor...
      let position = diffTimeInMinutes.indexOf(minValue);

      //retornamos la hora
      return times[position];
    },
    onSubmit(event) {
      var that = this;
      event.preventDefault();
      this.$store.state.endDate = that.calcular();

      that.form.signal = this.$store.state.currentSignals[0]._id;
      that.form.startAt = that.prettify(this.$store.state.endDate);
      that.form.validUntil = moment(new Date(this.$store.state.endDate))
        .add({ minutes: 14 })
        .add({ seconds: 59 });

      this.$store.state.endDate = moment(new Date(this.$store.state.endDate))
        .add({ minutes: 14 })
        .add({ seconds: 59 });

      if (that.$route.params.id === undefined && that.form._id !== null) {
        createCatalog(that.form)
          .then((result) => {
            alert("Catalogo creado de manera correcta.");
            setTimeout(() => {
              that.$router.push("/catalogo/" + undefined).catch((err) => {
                console.error(`router push error => ${err}`);
              });
            }, 400);
            this.$store.state.endDate = moment(
              new Date(this.$store.state.endDate)
            ).add({ seconds: 1 });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let newId = that.$route.params.id;
        if (newId === undefined) {
          newId = that.form._id;
        }
        updateCatalog(newId, that.form)
          .then((result) => {
            alert("Catalogo actualizado de manera correcta.");
            setTimeout(() => {
              that.$router.push("/catalogo/" + undefined).catch((err) => {
                console.error(`router push error => ${err}`);
              });
            }, 400);
            this.$store.state.endDate = moment(
              new Date(this.$store.state.endDate)
            ).add({ seconds: 1 });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checkAvailable() {
      let that = this;
      let inUse = [];
      that.$refs.signal.forEach((signal) => {
        inUse.push(signal.currentId);
      });
      that.availableSignals = that.signals.filter((signal) => {
        return !inUse.includes(signal._id) && signal.ipServer != "";
      });
    },

    prettify(ts) {
      return moment(ts).format("YYYY-MM-DD HH:mm:ss");
    },
    prettifyHour(ts) {
      return moment(ts).format("HH:mm:ss");
    },
    async onSearch(search, loading, slug) {
      var that = this;
      if (search.length) {
        let family = that.cruds.find((crud) => {
          return crud.slug == slug;
        });

        that.API["search" + slug](search)
          .then((res) => {
            if (slug === "Country") {
              that.form["country"] = res.data;
            }
            family.data = res.data;
          })
          .catch((err) => {
            console.error("error", err);
          });
      }
    },
    findCatalogByDate() {
      console.log("[findCatalogByDate] Ejecutando función");
      var that = this;
      if (!this.$store.state.live) {
        let data = {
          startAt: that.prettify(this.$store.state.currentTime),
          validUntil: moment(this.$store.state.currentTime)
            .add({ minutes: 14 })
            .add({ seconds: 59 }),
          signal: this.$store.state.currentSignals[0]._id,
        };
        console.log("datos para ir a buscar..", data);
        searchCatalogByDate(data)
          .then((response) => {
            that.$router.push("/catalogo/" + response.data._id).catch((err) => {
              console.error(`router push error => ${err}`);
            });
            that.form = response.data;
          })
          .catch((err) => {
            setTimeout(() => {
              that.$router.push("/catalogo/" + undefined).catch((err) => {
                console.error(`router push error => ${err}`);
              });
            }, 400);
            that.reset();
            console.error("[searchCatalogByDate] No se encontro registro ");
          });
      } else {
        setTimeout(() => {
          that.reset();
        }, 1000);
      }
    },
    reset() {
      let that = this;
      that.form = {};
      that.onSearch("Chile", false, "Country");
      that.onSearch(" ", false, "Person");
      that.onSearch(" ", false, "Show");
      that.onSearch(" ", false, "Topic");
      that.onSearch(" ", false, "Age");
      that.onSearch(" ", false, "Category");

      //initialization variable
      that.form["tipo"] = "Capitulo";
      that.form["exhibicion"] = "Primera exhibición";
      that.form["emision"] = "En vivo";
      that.form["pg"] = "No";
    },
  },
  computed: {
    user: {
      get() {
        let that = this;
        if (that.$session.exists() && that.$session.get("user") != undefined) {
          return that.$session.get("user");
        } else {
          return null;
        }
      },
    },
    signals: {
      get() {
        return this.$store.state.signals;
      },
    },
    signalsLeft: {
      get() {
        let that = this;
        return that.currentSignals.filter((signal, i) => {
          return i < 2;
        });
      },
    },
    signalsRight: {
      get() {
        let that = this;
        return that.currentSignals.filter((signal, i) => {
          return i >= 2 && i < 4;
        });
      },
    },
    signalsCurrent: {
      get() {
        let that = this;
        return that.signalsLeft.concat(that.signalsRight);
      },
    },
    data: {
      get() {
        let that = this;
        return [
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 30
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 60
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 90
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 90
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 90
          ),
        ];
      },
    },
    availableSignals: {
      get() {
        return this.$store.state.availableSignals;
      },
      set(value) {
        this.$store.commit("SET_AVAILABLE_SIGNALS", value);
      },
    },
    currentSignals: {
      get() {
        return this.$store.state.currentSignals;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_SIGNALS", value);
      },
    },
    time: {
      get() {
        return this.$store.state.time;
      },
      set(value) {
        this.$store.commit("SET_TIME", value);
      },
    },
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("SET_USER", value);
      },
    },
    views: {
      get() {
        return this.$store.state.views;
      },
      set(value) {
        this.$store.commit("SET_VIEWS", value);
      },
    },
  },
  watch: {
    "$store.state.currentTime": {
      handler(newValue, oldValue) {
        let that = this;
        let newValueTime = new Date(newValue);
        let oldValueTime = new Date(oldValue);
        let diffInMinute = parseInt(
          (newValueTime - oldValueTime) / (1000 * 60),
          10
        );
        if (diffInMinute > 2 || diffInMinute < -2) {
          that.findCatalogByDate();
        }
      },
    },
    "form.person": {
      handler(newValue, oldValue) {
        var that = this;
        newValue.forEach(async (person, index) => {
          if (!person._id) {
            let json = {
              name: person,
              active: true,
              edit: true,
            };
            let result = await that.API["createPerson"](json);
            that.form.person[index] = result.data;
          }
        });
      },
    },
    "form.topic": {
      handler(newTopic, oldValue) {
        var that = this;
        newTopic.forEach(async (topic, index) => {
          if (!topic._id) {
            let json = {
              name: topic,
              active: true,
              edit: true,
            };
            let result = await that.API["createTopic"](json);
            that.form.topic[index] = result.data;
          }
        });
      },
    },
    "form.category": {
      handler(newcategory, oldValue) {
        var that = this;
        console.log(newcategory);
        newcategory.forEach(async (topic, index) => {
          if (!topic._id) {
            let json = {
              name: topic,
              active: true,
              edit: true,
            };
            let result = await that.API["createCategory"](json);
            that.form.category[index] = result.data;
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-tablet: 735px;

.app {
  grid-area: app;
  display: flex;
  width: 100vw;
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .col {
    height: 100%;
    padding: 0px;

    .form-group {
      color: white;
      text-align: left;
    }

    &.dynamic {
      min-width: 16vw;
      margin: 0px 10px;
    }

    &.middle {
      grid-area: middle;
      min-height: 80px;
      overflow-x: auto;
      flex: 1 1 auto;

      @media only screen and (hover: none) and (pointer: coarse) and (orientation: portrait) {
        position: fixed;
        bottom: 0vh;
        width: 100vw;
        height: 60px;
        min-height: 60px;
        -webkit-backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }

      @media only screen and (hover: none) and (pointer: coarse) and (orientation: landscape) {
        position: fixed;
        bottom: -60px;
        width: 100vw;
        height: 60px;
        min-height: 60px;
        -webkit-backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
.labelStyle {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.catalog {
  height: 100%;
  grid-area: col1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-content: stretch;
  box-sizing: border-box;
  background-color: black;
  border-right: 2px solid #aaa;
  box-shadow: 1px 0px 15px 0px rgba(0, 0, 0, 0.7);

  .signal {
    height: 100%;
  }
}
</style>
