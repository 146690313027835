var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin p-4"},[(!_vm.isAdmin)?_c('h2',{class:{ 'text-light': true }},[_vm._v("Mis clips")]):_vm._e(),(_vm.isAdmin)?_c('h2',{class:{ 'text-light': true }},[_vm._v(" Todos los clips ")]):_vm._e(),_c('b-row',[_c('b-col',{class:{ 'my-3': true },attrs:{"lg":"6"}}),_c('b-col',{class:{ 'my-3': true },attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Filter","label-for":"filter-input","label-cols-sm":"12","label-align-sm":"left","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Filtrar clips..."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Limpiar")])],1)],1)],1)],1)],1),_c('b-table',{attrs:{"striped":"","dark":"","hover":"","filter":_vm.filter,"items":_vm.items,"fields":_vm.fields,"tbody-tr-class":_vm.rowClass,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"filtered":_vm.onFiltered,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(row.item.hasOwnProperty('_id'))?_c('div',{staticClass:"d-flex justify-content-evenly"},[_c('b-button',{staticClass:"btn-danger",on:{"click":function($event){return _vm.deleteCrop(row.item._id)}}},[_c('i',{staticClass:"fa fa-2x fa-trash"})])],1):_vm._e()]}},{key:"cell(signal)",fn:function(row){return [_c('div',{staticClass:"signal-logo mx-auto",style:('background-image:url(https://13checkapi.ingenieriac13.cl/' +
          row.item.signal.logo +
          ');')})]}},{key:"cell(author_formatted)",fn:function(row){return [_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(row.item.author_formatted)}})]}},{key:"cell(timestampStartFormat)",fn:function(row){return [_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(row.item.timestampStartFormat)}})]}},{key:"cell(timestampEndFormat)",fn:function(row){return [_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(row.item.timestampEndFormat)}})]}},{key:"cell(created_atFormat)",fn:function(row){return [_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(row.item.created_atFormat)}})]}},{key:"cell(duration)",fn:function(row){return [_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(row.item.duration)}})]}},{key:"cell(output)",fn:function(row){return [(row.item.output)?_c('b-button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.playVideo(row.item._id)}}},[_c('i',{staticClass:"fa fa-2x fa-play"})]):_vm._e(),(row.item.output)?_c('a',{staticClass:"btn btn-success ml-2",attrs:{"href":'https://13checkqa.ingenieriac13.cl/crop/' +
          row.item.signal.ipServer +
          '/getcrop/' +
          encodeURIComponent(row.item.author.email) +
          '/' +
          encodeURIComponent(row.item.fileid) +
          '/download'}},[_c('i',{staticClass:"fa fa-2x fa-download"})]):_vm._e()]}},{key:"cell(active)",fn:function(row){return [_c('toggle-button',{attrs:{"color":"#F86423","sync":true,"labels":{ checked: 'Activo', unchecked: 'Inactivo' },"width":80},on:{"change":function($event){return _vm.saveToggle(row.item._id, 'active', row.item.active)}},model:{value:(row.item.active),callback:function ($$v) {_vm.$set(row.item, "active", $$v)},expression:"row.item.active"}}),(
          _vm.loading && _vm.currentId == row.item._id && _vm.currentField == 'active'
        )?_c('b-spinner',{staticClass:"canal13 ml-2 mt-2 position-absolute top-0 end-0",attrs:{"variant":"success","label":"Spinning"}}):_vm._e()]}},{key:"cell(share)",fn:function(row){return [_c('div',{staticClass:"row"},[_c('div',{class:{ 'col-md-6': row.item.share, 'col-12': true }},[_c('toggle-button',{attrs:{"color":"#F86423","sync":true,"labels":{ checked: 'Compartir', unchecked: 'No compartir' },"width":110},on:{"change":function($event){return _vm.saveToggle(row.item._id, 'share', row.item.share)}},model:{value:(row.item.share),callback:function ($$v) {_vm.$set(row.item, "share", $$v)},expression:"row.item.share"}}),(
              _vm.loading && _vm.currentId == row.item._id && _vm.currentField == 'share'
            )?_c('b-spinner',{staticClass:"canal13 ml-2 mt-2 position-absolute top-0 end-0",attrs:{"variant":"success","label":"Spinning"}}):_vm._e()],1),(row.item.share)?_c('div',{class:{ 'col-md-6': row.item.share, 'col-12': true }},[_c('toggle-button',{attrs:{"color":"#198754","sync":true,"labels":{ checked: 'Descarga', unchecked: 'Sin descarga' },"width":110},on:{"change":function($event){return _vm.saveToggle(row.item._id, 'download', row.item.download)}},model:{value:(row.item.download),callback:function ($$v) {_vm.$set(row.item, "download", $$v)},expression:"row.item.download"}}),(
              _vm.loading &&
              _vm.currentId == row.item._id &&
              _vm.currentField == 'download'
            )?_c('b-spinner',{staticClass:"canal13 ml-2 mt-2 position-absolute top-0 end-0",attrs:{"variant":"success","label":"Spinning"}}):_vm._e()],1):_vm._e()]),(row.item.share)?_c('div',{staticClass:"row d-flex flex-nowrap w-100 mx-auto mt-2"},[_c('div',{class:{ 'col-md-9': row.item.share, 'col-12': true }},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.item.share_link.permalink),expression:"row.item.share_link.permalink"}],class:{
                'form-control clipboard text-right': true,
                loading: row.item.copying == 'loading',
              },attrs:{"type":"text","dir":"rtl","id":"inlineFormInputGroup","placeholder":"Link para compartir","readonly":""},domProps:{"value":(row.item.share_link.permalink)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row.item.share_link, "permalink", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('b-button',{staticClass:"btn-primary input-group-text rounded-right",attrs:{"alt":"Copiar link"},on:{"click":function($event){return _vm.copyLink(row.item)}}},[(!row.item.copying || row.item.copying == 'idle')?_c('i',{staticClass:"fa fa-copy"}):_vm._e(),(row.item.copying == 'loading')?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),(row.item.copying == 'done')?_c('i',{staticClass:"fas fa-check"}):_vm._e()])],1)])]),_c('div',{class:{
            'col-md-3': row.item.share,
            'col-12 d-flex flex-column': true,
          }},[_c('small',[_vm._v("Vistas:")]),_c('span',[_c('strong',[_vm._v(_vm._s(row.item.views))])])])]):_vm._e()]}}])}),_c('b-pagination',{class:{ 'my-0': true },attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm","dark":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-modal',{staticClass:"backdropblur",attrs:{"id":"player","title":"BootstrapVue","size":"xl"},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
          var close = ref.close;
return [_c('span',[_vm._v("Reproductor de video")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-warning"},on:{"click":function($event){return _vm.closeModal(close)}}},[_vm._v(" X ")])]}},{key:"modal-footer",fn:function(ref){
          var close = ref.close;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('b-button',{staticClass:"ml-auto",attrs:{"size":"md","variant":"primary"},on:{"click":function($event){return _vm.closeModal(close)}}},[_vm._v(" Cerrar ")])],1)]}}])},[_c('video-player',{attrs:{"options":_vm.videoOptions,"muted":false,"url":_vm.currentUrl,"controls":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }