var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'pt-2': true,
    'd-flex flex-column': true,
  },on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.sliderKeyLeft($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return _vm.sliderKeyRight($event)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }_vm.play != _vm.play}}},[(_vm.user.role.allowRating && _vm.rating != false)?_c('div',{staticStyle:{"width":"740px"}},[_c('rating')],1):_vm._e(),_c('div',{staticClass:"\n      slider-wrapper\n      d-flex\n      flex-row flex-nowrap\n      justify-content-around\n      px-2\n    "},[_c('div',{class:{
        'transition text-light overflow-hidden text-nowrap datetime': true,
        'p-0 col-0': !_vm.range,
      },on:{"click":function($event){_vm.openStartDatepicker = true}}},[_c('div',{staticClass:"date"},[_c('p',[_vm._v(_vm._s(_vm.prettifyTooltip(_vm.startTime, "DD-MM-YYYY")))])]),_c('div',{staticClass:"time"},[_c('strong',[_vm._v(_vm._s(_vm.prettifyTooltip(_vm.startTime, "HH:mm:ss")))])]),_c('date-picker',{staticClass:"invisible h-0",attrs:{"type":"datetime","format":"DD-MM-YYYY HH:mm:ss","time-title-format":"DD-MM-YYYY HH:mm:ss","open":_vm.openStartDatepicker,"show-time-header":true,"disabled-date":function (date) { return date > new Date(_vm.endDate); },"confirm":true},on:{"update:open":function($event){_vm.openStartDatepicker=$event}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_vm._m(0)],1),_c('div',{class:{
        'transition d-flex align-items-center fluid': true,
      }},[_c('select',{staticClass:"custom-select",staticStyle:{"width":"70px","background-color":"black","color":"white"},attrs:{"disabled":_vm.endTime === 100},on:{"change":function($event){return _vm.changeSpeed($event)}}},_vm._l((_vm.speedTime),function(item){return _c('option',{key:item.id,domProps:{"value":item.velocidad,"selected":item.selected}})}),0),_c('vue-slider',{attrs:{"contained":"true","width":"100%","min":0,"max":100,"interval":0.01,"enable-cross":false,"tooltip":'hover',"tooltip-formatter":_vm.prettifyTooltip,"silent":true,"dragOnClick":true},on:{"change":_vm.sliderChanged,"dragging":_vm.sliderDragging,"drag-start":_vm.sliderDragStart},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
      var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }]})]}}]),model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}})],1),_c('div',{class:{
        'transition text-light overflow-hidden text-nowrap datetime': true,
      },on:{"click":function($event){_vm.openEndDatepicker = true}}},[_c('div',{staticClass:"date"},[_c('p',[_vm._v(_vm._s(_vm.prettifyTooltip(_vm.endTime, "DD-MM-YYYY")))])]),_c('div',{staticClass:"time"},[_c('strong',[_vm._v(_vm._s(_vm.prettifyTooltip(_vm.endTime, "HH:mm:ss")))])]),_c('date-picker',{staticClass:"invisible h-0",attrs:{"popup-class":"popup","type":"datetime","format":"DD-MM-YYYY HH:mm:ss","time-title-format":"DD-MM-YYYY HH:mm:ss","open":_vm.openEndDatepicker,"show-time-header":true,"disabled-date":function (date) { return date >= new Date(); },"confirm":true},on:{"update:open":function($event){_vm.openEndDatepicker=$event}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_vm._m(1)],1)]),_c('div',{staticClass:"slider-wrapper-2 d-flex flex-column justify-content-around px-2"},[_c('div',{staticClass:"btn-group col-12 mt-2",attrs:{"role":"group","aria-label":"toolbar"}},[(!_vm.range && _vm.crop != false)?_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': !_vm.range,
          'btn-primary': _vm.range,
        },attrs:{"type":"button"},on:{"click":function($event){_vm.range = !_vm.range}}},[_c('i',{staticClass:"fa fa-2x fa-cut"}),_c('small',[_vm._v("Cortar clip")])]):_vm._e(),(_vm.range)?_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': !_vm.range,
          'btn-info': _vm.range,
        },attrs:{"type":"button"},on:{"click":_vm.zoomOut}},[_c('i',{staticClass:"fas fa-2x fa-search-minus"}),_c('small',[_vm._v("Alejar")])]):_vm._e(),(_vm.range)?_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': !_vm.range,
          'btn-info': _vm.range,
        },attrs:{"type":"button"},on:{"click":_vm.zoomIn}},[_c('i',{staticClass:"fas fa-2x fa-search-plus"}),_c('small',[_vm._v("Acercar")])]):_vm._e(),_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': !_vm.play,
          'btn-primary': _vm.play,
        },attrs:{"type":"button"},on:{"click":function($event){_vm.play = !_vm.play}}},[(!_vm.play)?_c('i',{staticClass:"fa fa-2x fa-play"}):_vm._e(),(!_vm.play)?_c('small',[_vm._v("Play")]):_vm._e(),(_vm.play)?_c('i',{staticClass:"fa fa-2x fa-pause"}):_vm._e(),(_vm.play)?_c('small',[_vm._v("Pausar")]):_vm._e()]),_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': _vm.muted,
          'btn-primary': !_vm.muted,
        },attrs:{"type":"button"},on:{"click":function($event){_vm.muted = !_vm.muted}}},[(!_vm.muted)?_c('i',{staticClass:"fas fa-2x fa-volume-up"}):_vm._e(),(!_vm.muted)?_c('small',[_vm._v("Mute")]):_vm._e(),(_vm.muted)?_c('i',{staticClass:"fas fa-2x fa-volume-mute"}):_vm._e(),(_vm.muted)?_c('small',[_vm._v("Mute")]):_vm._e()]),_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': !_vm.live,
          'btn-primary disabled': _vm.live,
        },attrs:{"type":"button"},on:{"click":_vm.goLive}},[_c('i',{staticClass:"fas fa-2x fa-broadcast-tower"}),_c('small',[_vm._v("En vivo")])]),(_vm.isNextTime)?_c('button',{staticClass:"btn-danger",class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
        },attrs:{"type":"button"},on:{"click":_vm.lessTime}},[_c('i',{staticClass:"fas fa-1x fa-angle-double-left"}),_vm._v("15 minutos previos ")]):_vm._e(),(_vm.isNextTime)?_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-success': !_vm.live,
          'btn-secondary disabled': _vm.live,
        },attrs:{"type":"button"},on:{"click":_vm.nextTime}},[_c('i',{staticClass:"fas fa-1x fa-angle-double-right"}),_vm._v("Proximos 15 minutos ")]):_vm._e()]),(_vm.range)?_c('div',{staticClass:"btn-group col-12 mt-2",attrs:{"role":"group","aria-label":"toolbar"}},[_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center': true,
          'btn-secondary': !_vm.range,
          'btn-danger': _vm.range,
        },attrs:{"type":"button"},on:{"click":function($event){_vm.range = !_vm.range}}},[_c('i',{staticClass:"fa fa-2x fa-times"}),_c('small',[_vm._v("Cancelar")])]),_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center disabled': true,
        },attrs:{"type":"button","disabled":""}}),(_vm.currentSignal)?_c('button',{class:{
          'btn d-flex flex-column align-items-center justify-content-center btn-secondary': true,
        },style:('background-color:' +
          _vm.currentSignal.colorBorde +
          ';border-color:' +
          _vm.currentSignal.colorBorde),attrs:{"type":"button"},on:{"click":_vm.exportCrop}},[_c('i',{staticClass:"fa fa-2x fa-cut"}),_c('small',[_vm._v("Cortar clip")])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-primary px-3",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-calendar",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-primary px-3",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-calendar",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }