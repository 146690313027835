<template>
  <div class="container-fluid admin ">
    <!--Version desktop --->
    <div v-if="!isMobile()">
      <div class="row">
        <div class="col fadeIn first signals">
          <!--- signalsLeft  --->
          <signal
            :ref="'signal'"
            :index="i"
            v-bind:key="i"
            v-for="(signal, i) in signalsLeft"
            header="left"
          />
        </div>
        <!--- signalsRight  --->
        <div class="col fadeIn first signals">
          <signal
            :ref="'signal'"
            :index="i + 2"
            v-bind:key="i"
            v-for="(signal, i) in signalsRight"
            header="left"
          />
        </div>
        <div class="col middle fadeIn second ratingComparator">
          <slider :rating="true" />
        </div>
      </div>
    </div>

    <!--Version Mobile--->
    <div v-else>
      <div class="row" style="justify-content: center;">
        <div class="col-sm-12 col-lg-6">
          <slider :rating="false" />
        </div>
      </div>
      <div class="row">
        <div
          class=" col-sm-6 col-md-6 col-lg-6"
          v-bind:key="i"
          v-for="(signal, i) in signals"
        >
          <signal :ref="'signal'" :index="i" />
        </div>
      </div>
      <br />
      <br />
      <div class="col-sm-12 col-lg-12 col-sm-12 mt-4 row middle fadeIn second ">
        <rating />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Rating from "@/components/Rating.vue";
import Loader from "@/components/Loader.vue";
import Slider from "@/components/Slider.vue";
import signal from "@/components/Signal.vue";
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";

export default {
  name: "Comparador",
  components: {
    Loader,
    Slider,
    IOdometer,
    signal,
    Rating,
  },
  data() {
    return {};
  },
  created() {
    var that = this;
    if (!that.$session.exists()) {
      window.location = "/login";
    }
  },
  async mounted() {
    var that = this;
    // the next line is for hidden the cut clip
    if (!this.$store.state.live) {
      location.reload();
    }

    setTimeout(() => {
      that.currentSignals = [];
      for (let i = 0; i < 4; i++) {
        that.currentSignals.push(that.signals[i]);
      }
    }, 500);
    setTimeout(() => {
      that.checkAvailable();
    }, 750);

    that.$root.$on("checkAvailable", () => {
      that.checkAvailable();
    });
  },
  methods: {
    checkAvailable() {
      let that = this;
      let inUse = [];
      that.$refs.signal.forEach((signal) => {
        inUse.push(signal.currentId);
      });
      that.availableSignals = that.signals.filter((signal) => {
        return !inUse.includes(signal._id) && signal.ipServer != "";
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  computed: {
    user: {
      get() {
        let that = this;
        if (that.$session.exists() && that.$session.get("user") != undefined) {
          return that.$session.get("user");
        } else {
          return null;
        }
      },
    },
    signals: {
      get() {
        return this.$store.state.signals;
      },
    },
    signalsLeft: {
      get() {
        let that = this;
        return that.currentSignals.filter((signal, i) => {
          return i < 2;
        });
      },
    },
    signalsRight: {
      get() {
        let that = this;
        return that.currentSignals.filter((signal, i) => {
          return i >= 2 && i < 4;
        });
      },
    },
    signalsCurrent: {
      get() {
        let that = this;
        return that.signalsLeft.concat(that.signalsRight);
      },
    },
    data: {
      get() {
        let that = this;
        return [
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 30
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 60
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 90
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 90
          ),
          new Date(that.minTime).setMinutes(
            new Date(that.minTime).getMinutes() + 90
          ),
        ];
      },
    },
    availableSignals: {
      get() {
        return this.$store.state.availableSignals;
      },
      set(value) {
        this.$store.commit("SET_AVAILABLE_SIGNALS", value);
      },
    },
    currentSignals: {
      get() {
        return this.$store.state.currentSignals;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_SIGNALS", value);
      },
    },
    time: {
      get() {
        return this.$store.state.time;
      },
      set(value) {
        this.$store.commit("SET_TIME", value);
      },
    },
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("SET_USER", value);
      },
    },
    views: {
      get() {
        return this.$store.state.views;
      },
      set(value) {
        this.$store.commit("SET_VIEWS", value);
      },
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.row {
  .signals:nth-of-type(2) {
    order: 3;
  }
  .middle {
    order: 1;
  }

  @media (max-width: 769px) {
    .signals:nth-of-type(2) {
      order: 2;
    }
    .middle {
      order: 3;
    }
  }
}
</style>
